import {formatDate, fetchObjectSubset} from 'app/lib/utils';
import {connect} from 'react-redux'
import {reloadManagers, reloadTags} from 'app/store/helpers'
import qs from 'query-string'

// /staff/managers/rates
class RatingFeed extends Component {
  state = { from: null, to: null, managerIds: [], previousUrl: "" }

  componentDidMount() {
    this.getData();
    const query = qs.parse(this.props.location.search);
    const managers = query.managers ? query.managers.split(",").map(a => Number(a)) : [];
    this.setState({managerIds: managers, previousUrl: this.props.location.search});
  }

  componentDidUpdate() {
    const currentUrl = this.props.location.search;
    const previousUrl = this.state.previousUrl;
    if (currentUrl !== previousUrl) {
      this.getData();
    }
  }

  getData() {
    reloadManagers();
    reloadTags();
    const params = this.apiRequestParams();

    API.managers.ratingFeed.get({
      params: params,
      success: ({ticket_events, pagination}) => this.setState({ rates: ticket_events, pagination }),
      error: data => console.log(data),
      complete: () => this.setState({ lastRequestParams: params, previousUrl: this.props.location.search }),
    });
  }

  onClick = async () => {
    const {managerIds} = this.state;
    const generateUrl = managerIds.length > 0 ? `/staff/managers/rates?managers=${managerIds}` : '/staff/managers/rates';
    await browserHistory.push(generateUrl)
    this.getData()
  }

  render() {
    if (!this.state.rates) {
      return <PageTransition />;
    }

    const {managers, currentUser} = this.props;
    const {rates, from, to} = this.state;

    const isPartner = currentUser.kind == 'manager' && currentUser.isPartner();

    return (
      <layouts.Staff>
        <Card stretch>
          <Margin size="20 0">
            <h2>Лента оценок</h2>
          </Margin>

          {!isPartner && this.renderManagerSelector()}

          <Margin size="15" />
          <TimeFilter from={from} to={to} onChange={({from, to}) => this.setState({ from, to })}/>
          <Margin size="15" />
          {this.renderSupportOpinionFilter()}
          <Margin size="15" />
          {this.renderPlatformOpinionFilter()}
          <Margin size="15" />
          {this.renderCommentPresenceFilter()}
          {this.renderMotivationFilter()}

          <Margin size="20 0" />
          <Button onClick={() => this.onClick()}>Выполнить</Button>
          <Margin size="20 0" />

          {rates.map(a => this.renderRate(a))}
          <Margin size='8' />
          {this.renderPagination()}
        </Card>
      </layouts.Staff>
    );
  }

  apiRequestParams() {
    const query = qs.parse(this.props.location.search);
    const managers = query.managers ? query.managers.split(",") : [];
    const page = query.page || 1;
    const {from, to, opinion, platformOpinion, withCommentOnly, motivationOnly} = this.state;
    return { manager_id: managers, with_comment_only: withCommentOnly,
             motivation_only: motivationOnly,
             page, from, to, opinion, platform_opinion: platformOpinion,
             with_opinion_only: true };
  }

  renderPagination() {
    const managers = qs.parse(this.props.location.search).managers;
    const baseUrl = `/staff/managers/rates?page=:page${managers ? ('&managers=' + managers) : ''}`;
    const onChange = (page) => {
      const url = baseUrl.replace(':page', page);
      browserHistory.replace(url);
    }
    return <Pagination data={this.state.pagination} onChange={onChange} />
  }

  renderManagerSelector() {
    const onChange =(ids) => this.setState({managerIds: ids}, () => console.log(ids)) ;
    return <staff.ManagerMultiselect selected={this.state.managerIds} onChange={onChange} />;
  }

  renderSupportOpinionFilter() {
    const items = [
      { id: null,      label: 'Оценка сотрудника' },
      { id: 'bad',     label: 'Плохая' },
      { id: 'neutral', label: 'Нейтральная' },
      { id: 'good',    label: 'Хорошая' },
    ];

    const current = this.state.opinion || null;

    return (
      <DropdownTrigger items={items} onSelect={opinion => this.setState({ opinion })}>
        {items.find(item => item.id == current).label}
      </DropdownTrigger>
    );
  }

  renderPlatformOpinionFilter() {
    const items = [
      { id: null,      label: 'Оценка платформы' },
      { id: 'bad',     label: 'Плохая' },
      { id: 'neutral', label: 'Нейтральная' },
      { id: 'good',    label: 'Хорошая' },
    ];

    const current = this.state.platformOpinion || null;

    return (
      <DropdownTrigger items={items} onSelect={platformOpinion => this.setState({ platformOpinion })}>
        {items.find(item => item.id == current).label}
      </DropdownTrigger>
    );
  }

  renderCommentPresenceFilter() {
    const {withCommentOnly} = this.state;
    const setValue = v => this.setState({ withCommentOnly: v });

    return <Checkbox label='Только с комментарием'
                            value={withCommentOnly || false}
                            onChange={setValue} />;
  }

  renderMotivationFilter() {
    const {motivationOnly} = this.state;
    const setValue = v => this.setState({ motivationOnly: v });

    return (
      <div>
        <Checkbox label='Только мотивационные'
                         value={motivationOnly || false}
                         onChange={setValue} />
      </div>
    );
  }

  renderRate(rate) {
    const findManager = id => this.props.managers.find(m => m.id == id);
    const linkToTicket = ticket => `/staff/${ticket.id}`;
    const ticket = rate.ticket;
    const answeredAt = rate.data?.date
    const beforeLabel = answeredAt ? formatDate(answeredAt) : 'Нет даты'
    const personNote = (
      <span>
        ({beforeLabel} за тикет <Link to={linkToTicket(ticket)}>{rate.ticket.subject}</Link>)
      </span>
    );

    return (
      <div key={rate.id} >
        <staff.RatingEvent event={rate} manager={findManager(rate.manager_id)} personNote={personNote} />
      </div>
    );
  }
}

const mapStateToProps = s => fetchObjectSubset(s, ['managers', 'currentUser']);
export default connect(mapStateToProps)(RatingFeed);
