import Button from "../Button";

class RateTicket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      supportOpinion: this.getDefaultEmotion(),
      platformOpinion: this.getDefaultEmotion(),
      message: ''
    };
  }

  getAnswer() {
    return this.props.ticketRate?.data['answer']
  }

  getDefaultEmotion() {
    const answer = this.getAnswer()
    if (answer) {
      return answer === 'yes' ? 'good' : 'bad'
    }

    return null
  }

  renderMessage = () => {
    const { message } = this.state
    const answer = this.getAnswer()
    let placeholder = 'Комментарий (по желанию)'
    if (answer === 'no') {
      placeholder = "Пожалуйста, напишите, какое решение полностью бы Вас устроило или что было не так? Это поможет нам скорректировать работу техподдержки."
    }

    return (
      <Textarea value={message}
                onChange={message => this.setState({ message })}
                placeholder={placeholder}
      />
    )
  }

  renderEmotions = () => {
    const { supportOpinion, platformOpinion } = this.state

    return (
      <>
        <div className='rate-ticket__label'>
          Оцените работу сотрудника
        </div>
        <div className="rate-ticket__emotions">
          <RatingIcon opinion='good'
                      size={60}
                      uncolored={supportOpinion != 'good'}
                      onClick={() => this.setState({ supportOpinion: 'good' })} />
          <RatingIcon opinion='neutral'
                      size={60}
                      uncolored={supportOpinion != 'neutral'}
                      onClick={() => this.setState({ supportOpinion: 'neutral' })} />
          <RatingIcon opinion='bad'
                      size={60}
                      uncolored={supportOpinion != 'bad'}
                      onClick={() => this.setState({ supportOpinion: 'bad' })} />
        </div>
        <div className='rate-ticket__label'>
          Оцените работу платформы inSales
        </div>
        <div className="rate-ticket__emotions">
          <RatingIcon opinion='good'
                      size={60}
                      uncolored={platformOpinion != 'good'}
                      onClick={() => this.setState({ platformOpinion: 'good' })} />
          <RatingIcon opinion='neutral'
                      size={60}
                      uncolored={platformOpinion != 'neutral'}
                      onClick={() => this.setState({ platformOpinion: 'neutral' })} />
          <RatingIcon opinion='bad'
                      size={60}
                      uncolored={platformOpinion != 'bad'}
                      onClick={() => this.setState({ platformOpinion: 'bad' })} />
        </div>
      </>
    )
  }

  render() {
    const { ticketRate } = this.props

    if (!ticketRate || !ticketRate.data) {
      return (
        <div className='rate-ticket'>
          <div className='rate-ticket__label'>
            Уточните, была ли решена ваша проблема?
          </div>
          <div className='rate-ticket__answer_buttons' style={{textAlign: "left"}}>
            <Button onClick={() => this.props.onCreate('yes')}>Да</Button>
            <Button outline onClick={() => this.props.onCreate('no')}>Нет</Button>
          </div>
          <div className='rate-ticket__label'>
            Ваш ответ поможет нам улучшить качество обслуживания и быстрее решать вопросы в будущем.
          </div>
          <Margin size='20' />
        </div>
      )
    }

    switch (ticketRate.data.answer) {
      case 'yes':
        return (
          <div className='rate-ticket'>
            <div className='rate-ticket__label'>
              Пожалуйста, оцените нашу работу
            </div>
            {this.renderEmotions()}
            {this.renderMessage()}
            <div className="rate-ticket__controls">
              <Button onClick={() => this.onSubmit()}>Оценить</Button>
            </div>
          </div>
        )
      case 'no':
        return (
          <div className='rate-ticket'>
            {this.renderMessage()}
            {this.renderEmotions()}
            <div className="rate-ticket__controls">
              <Button onClick={() => this.onSubmit()}>Оценить</Button>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  onSubmit() {
    const { supportOpinion, platformOpinion, message } = this.state;

    this.props.onComplete(supportOpinion, platformOpinion, message)
  }
}


export default RateTicket;
