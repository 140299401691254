import { connect } from 'react-redux'
import { reloadTags, reloadManagers, reloadScripts, setDocumentTitle, reloadCategories } from 'app/store/helpers'
import pages from 'app/pages'
import Select from 'react-select'
import ControlBlock from './partials/ControlBlock'
import TicketSubscriptionMenu from './partials/TicketSubscriptionMenu'
import qs from 'query-string'
import Button from "../../../ui/Button";

class TicketShowPage extends Component {
  state = {
    ticket: {},
    events: [],
    loading: false,
    successNotice: null,
    errorNotice: null,
    primaryAutoCategory: null,
    subAutoCategory: null,
    selectedCategories: []
  }

  componentDidMount() {
    this.getData();
  }

  saveAutoCategories = () => {
    const { ticket, primaryAutoCategory, subAutoCategory } = this.state;

    API.tickets.updateAutoCategory({
      id: ticket.id,
      params: {
        auto_categorization: {
          // Отправляем только ID родительской категории, если дочерняя не выбрана
          category_ids: subAutoCategory ? [primaryAutoCategory?.value, subAutoCategory?.value] : [primaryAutoCategory?.value]
        }
      },
      success: () => {
        this.setState({
          successNotice: 'Автокатегории успешно обновлены.',
          errorNotice: null  // Обнуляем сообщение об ошибке
        });
        setTimeout(() => this.hideSuccessNotice(), 3000); // Убираем уведомление через 3 секунды

        this.getData(); // Загрузка данных заново после обновления
      },
      error: (xhr) => {
        console.error('Ошибка сохранения автокатегорий:', xhr);
        this.setState({
          errorNotice: `Ошибка сохранения автокатегорий: ${xhr.responseText}`,
          successNotice: null
        });
      }
    });
  };

  // Метод для скрытия уведомления об успехе
  hideSuccessNotice = () => {
    this.setState({successNotice: null});
  };

  componentDidUpdate() {
    if(!this.isLoadingInProgress() && this.needToUpdateData()) {
      this.getData();
    }

    const { ticket: { subject } } = this.state;

    if(subject) {
      setDocumentTitle(`#${this.props.match.params.id - 0} - ${subject}`)
    }
  }

  isLoadingInProgress() {
    const {loading, eventsLoading} = this.state;
    return loading || eventsLoading;
  }

  needToUpdateData() {
    if(this.isPermissionDenied())
      return false;
    const {ticket, eventsLoadedFor} = this.state;
    const requiredTicketId = this.props.match.params.id - 0;
    return ticket.id != requiredTicketId || eventsLoadedFor != requiredTicketId;
  }

  getAccountFreeWorks() {
    const {account_id} = this.state.ticket;
    if(!account_id)
      return;

    const repo = new repositories.FreeWorkRepo();
    repo.getThisMonth({ account_id: account_id, freePlan: this.isFreePlan() })
      .then(works => this.setState({ accountFreeWorks: works }))
      .catch(data => console.log(data))
  }

  // TODO: Возможно стоит положить атрибут бесплатности тарифа в тикет и брать его с бэка.
  isFreePlan() {
    return this.state.ticket?.plan?.toLowerCase()?.startsWith("бесплатный");
  }

  setSelectedAutoCategories = (ticket) => {
    const first = ticket.auto_categorizations.find(({ category }) => !category.parent_id)
    const second = ticket.auto_categorizations.find(({ category }) => category.parent_id)

    const primaryAutoCategory = first && { value: first.category.id, label: first.category.name }
    const subAutoCategory = second && { value: second.category.id, label: second.category.name }

    this.setState({ primaryAutoCategory, subAutoCategory })
  }

  setSelectedCategories(ticket) {
    const selectedCategories = []

    ticket.tags.forEach((tag) => {
      let categoryId, subCategoryId = null

      ticket.categories.forEach(({ public_tag_id, parent_id, id }) => {
        if (public_tag_id === tag.id) {
          if (parent_id) {
            subCategoryId = id
          } else {
            categoryId = id
          }
        }
      })

      selectedCategories.push({
        tag: { id: tag.id, name: tag.name },
        categoryId,
        subCategoryId
      })
    })

    this.setState({ selectedCategories })
  }

  getData() {
    reloadManagers();
    reloadTags();
    reloadScripts();
    reloadCategories();

    const id = this.props.match.params.id;

    this.setState({ loading: true, accountFreeWorks: null });
    const repo = new repositories.TicketRepo();
    repo.get(id)
      .then((ticket) => {
        this.handleTicketResponse(ticket);
        this.getAccountFreeWorks();
      })
      .catch(data => {
        data.status === 403 ? this.setState({permissionDeniedFor: id}) : console.log(data);
        this.setState({loading: false});
      });

    API.ticketEvents.getAll({ ticketId: id,
                              success: events => this.setState({ events }),
                              error: data => console.log(data),
                              complete: () => this.setState({ eventsLoading: false,
                                                              eventsLoadedFor: id }) });
  }

  isPermissionDenied() {
    const requiredTicketId = this.props.match.params.id - 0;
    const permissionDeniedFor = this.state.permissionDeniedFor;
    return permissionDeniedFor === requiredTicketId;
  }

  getCategoriesOptions = (parentId = null) => {
    return this.props.categories
            .filter((category) => category.parent_id === parentId)
            .map(c => ({value: c.id, label: c.name}))
  }

  getTagCategoriesOptions = (tagId, parentId = null) => {
    return this.props.categories
            .filter((category) => category.parent_id === parentId && category.public_tag_id === tagId)
            .map(c => ({value: c.id, label: c.name}))
  }

  handleCategoryChange = (index, selected = null, sub = false) => {
    const selectedCategories = [ ...this.state.selectedCategories ]

    if (sub) {
      selectedCategories[index].subCategoryId = selected?.value
    } else {
      selectedCategories[index].categoryId = selected?.value
      selectedCategories[index].subCategoryId = null
    }

    this.setState({ selectedCategories });
  }

  handleTicketResponse = (ticket) => {
    this.setSelectedCategories(ticket)
    this.setSelectedAutoCategories(ticket);
    this.setState({ticket, loading: false});
    
    setDocumentTitle(`#${ticket.id} - ${ticket.subject}`);
  }

  saveCategories = (index) => {
    const { tag, categoryId, subCategoryId } = this.state.selectedCategories[index];
    const category_ids = [];

    if (categoryId) { category_ids.push(categoryId)}

    if (subCategoryId) { category_ids.push(subCategoryId) }

    API.tickets.updateCategories({
      id: this.props.match.params.id,
      params: { tag_id: tag.id, category_ids },
      success: this.handleTicketResponse,
      error: (xhr) => {
        console.error('Ошибка при обновлении категорий:', xhr);
      }
    })
  }

  render() {
    const { ticket, events, selectedScript, successNotice, errorNotice, primaryAutoCategory, subAutoCategory } = this.state;
    const { subject } = ticket;

    if(this.isPermissionDenied()) {
      return <pages.staff.permission_denied />
    }

    if(!ticket.id) {
      return <PageTransition />;
    }

    const query = qs.parse(this.props.location.search);

    return (
      <layouts.staff.SingleTicket
        ticket={ticket}
        freePlan={this.isFreePlan()}
        events={events}
        reloadData={() => this.getData()}>
        {selectedScript && this.renderScriptModal()}
        <div className="content-container">
          <SplitSides top>
            <div className="ticket-header">
              <h1>
                {this.renderTicketLabel()}
                &nbsp;
                <PopupPrompt key={subject} text='Введите новое имя' initialInput={subject}
                             onOk={v => this.renameTicket(v)}>
                  <Tooltip content='Переименовать'><Icon clickable name='create'/></Tooltip>
                </PopupPrompt>
              </h1>
            </div>
            {ticket.manager && <Person person={ticket.manager}/>}
          </SplitSides>
          <div className="auto-categories-container">
            <div className="ticket-categories">
              Категории:
              {ticket.categories && ticket.categories
                .sort((a, b) => {
                  // Сначала сортируем по родительскому ID (если таковое есть),
                  // затем - по самим ID, чтобы дочерние всегда шли после родительских.
                  if (a.parent_id === b.parent_id) {
                    return a.id - b.id;
                  }
                  return a.parent_id ? 1 : -1;
                })
                .map((category, index) => (
                  <React.Fragment key={category.id}>
                    <span className="ticket-category">{category.name}</span>
                    {index < ticket.categories.length - 1 && <span>, </span>}
                  </React.Fragment>
                ))}
            </div>
            <div className="ticket-categories-form">
              {this.state.selectedCategories.map(({ tag, categoryId, subCategoryId }, index) => {
                return (
                  <div className="ticket-categories-form__row" key={tag.id}>
                    <div className="ticket-categories-form__label">
                      {tag.name}
                    </div>
                    <div className="ticket-categories-form__select">
                      <Select value={categoryId}
                              onChange={(item) => this.handleCategoryChange(index, item, false)}
                              options={this.getTagCategoriesOptions(tag.id, null)}
                              placeholder="Категория"
                      />
                    </div>
                    <div className="ticket-categories-form__select">
                      {categoryId && (
                        <Select value={subCategoryId}
                                onChange={(item) => this.handleCategoryChange(index, item, true)}
                                options={this.getTagCategoriesOptions(tag.id, categoryId)}
                                placeholder="Подкатегория"
                        />
                      )}
                    </div>
                    <div className="ticket-categories-form__button">
                      <Button small onClick={() => this.saveCategories(index)}>
                        Сохранить
                      </Button>
                    </div>
                  </div>
                )
              })}
            </div>
            <hr className='separate'/>
            {/* <div className="auto-categories-display">
              Автокатегории:
              {ticket.auto_categorizations && ticket.auto_categorizations.map(({ category }, index) => (
                <React.Fragment key={category.id}>
                  <span className="ticket-category">
                    {category.name}
                  </span>
                  {index < ticket.auto_categorizations.length - 1 && ', '}
                </React.Fragment>
              ))}
            </div>
            <div className="ticket-categories-form">
              <div className="ticket-categories-form__row">
                <div className="ticket-categories-form__label">
                  Выбрать автокатегории:
                </div>
                <div className="ticket-categories-form__select">
                  <Select value={primaryAutoCategory?.value}
                          onChange={(item) => this.setState({ primaryAutoCategory: item, subAutoCategory: null })}
                          options={this.getCategoriesOptions()}
                          placeholder="Родительские автокатегории"
                  />
                </div>
                <div className="ticket-categories-form__select">
                  {primaryAutoCategory && (
                    <Select value={subAutoCategory?.value}
                            onChange={(item) => this.setState({ subAutoCategory: item })}
                            options={this.getCategoriesOptions(primaryAutoCategory.value)}
                            placeholder="Дочерние автокатегории"
                    />
                  )}
                </div>
                <div className="ticket-categories-form__button">
                  <Button small onClick={this.saveAutoCategories}>
                    Сохранить
                  </Button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {this.renderOldNumber()}

        {ticket.opinion_text &&
          <OpinionText text={ticket.opinion_text} additionalClasses="mt-2" opinion={ticket.opinion}/>}
        <Margin size="10"/>
        {successNotice && <Notice type="done">{successNotice}</Notice>}
        {errorNotice && <ErrorNotice errors={[errorNotice]}/>}
        <Margin size="10"/>

        <SplitSides>
          <TicketSubscriptionMenu key={ticket.id} ticket={ticket}/>
          {<TicketActivityStatus ticket={ticket}/>}
        </SplitSides>

        <Margin size="15"/>

        <ControlBlock ticket={ticket}
                      ref='topControlBlock'
                      showErrorNotice={message => this.showErrorNotice(message)}
                      triggerDataReload={() => this.getData()}
                      from_number_search={query.from_number_search}
                      formIdSuffix='top'
                      dispatchState={data => this.getData()}/>

        {this.renderEventFeed()}

        <ControlBlock ticket={ticket}
                      ref='bottomControlBlock'
                      showErrorNotice={message => this.showErrorNotice(message)}
                      triggerDataReload={() => this.getData()}
                      from_number_search={query.from_number_search}
                      formIdSuffix='bottom'
                      dispatchState={data => this.setState(data)}/>
      </layouts.staff.SingleTicket>
    );
  }

  renderEventFeed() {
    // FIXME: naming. updateMessage, onEditMessage. WTF?!
    const {updateMessage, events, ticket} = this.state;
    const {tags, managers} = this.props;
    const onEditMessage = event => this.setState({ updateMessage: event.id });
    const onUpdate = (event, data) => API.ticketEvents.update({
      id: event.id,
      ticketId: ticket.id,
      params: { message: data.message, attachment_ids: data.fileIds },
      success: updatedEvents => {
        this.getData();
        this.setState({ updateMessage: null, events: updatedEvents });
      },
      error: data => this.showErrorsFromResponse(data),
    });
    return <staff.TicketEventFeed events={events}
                                         tags={tags}
                                         managers={managers}
                                         onEditMessage={onEditMessage}
                                         updateMessage={updateMessage}
                                         onQuote={txt => this.quote(txt)}
                                         onUpdate={onUpdate}
                                         onDelete={e => this.deleteEvent(e)} />;
  }

  quote(txt) {
    const {reverseEventFeed} = this.props;
    const controlBlock = reverseEventFeed ? this.refs.topControlBlock : this.refs.bottomControlBlock;
    controlBlock.showMessageForm();

    const currentText = controlBlock.getMessageFormText();
    const newText = currentText.trim() ? `${currentText}\n\n${txt}\n\n` : `${txt}\n\n`
    controlBlock.setMessageFormText(newText);
    controlBlock.focusMessageForm();
  }

  renderTicketLabel() {
    const { ticket } = this.state;
    return (
      <span>
        {ticket.opinion && <RatingIcon opinion={ticket.opinion} />}
        {ticket.platform_opinion && <RatingIcon opinion={ticket.platform_opinion} />}
        {'#' + ticket.id}
        {' - ' + ticket.subject}
      </span>
    );
  }

  renderOldNumber() {
    const oldNumber = this.state.ticket.old_number;
    if(!oldNumber) {
      return null;
    }

    return <div>Номер в омни: <strong>{oldNumber}</strong></div>;
  }

  deleteEvent(event) {
    API.ticketEvents.delete({
      ticketId: this.state.ticket.id,
      id: event.id,
      success: events => this.setState({ events }),
      error: data => this.showErrorsFromResponse(data),
    });
  }

  renameTicket(name) {
    const {ticket} = this.state;

    API.tickets.rename({
      id: ticket.id,
      params: { name: name },
      success: () => this.setState({ ticket: { ...ticket, subject: name } }, this.getData),
      error: data => this.showErrorsFromResponse(data),
    });
  }

  showErrorsFromResponse({responseJSON}) {
    if(responseJSON && responseJSON.errors) {
      this.showErrorNotice(responseJSON.errors.join("; \n"));
    } else {
      this.showErrorNotice("unknown error");
    }
  }

  showErrorNotice(text) {
    clearTimeout(this.state.errorNoticeHidingTimeout);
    this.setState({ errorNotice: text || 'Произошла неизвестная ошибка',
                    errorNoticeHidingTimeout: setTimeout(() => this.hideErrorNotice(), 5000) });
  }

  hideErrorNotice() {
    this.setState({ errorNotice: null });
  }
}

const mapStateToProps = s => ({ reverseEventFeed: s.userSettings.reverseEventFeed,
                                tags: s.tags,
                                categories: s.categories,
                                managers: s.managers });
export default connect(mapStateToProps)(TicketShowPage);
